import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["system"],
  "status": ["completed"],
  "title": "GROW-E",
  "subtitle": "Refurbishing a dishwasher machine into a hydroponic kitchen herb growing machine.",
  "description": "",
  "years": ["2015"],
  "institutions": ["dtu compute"],
  "location": "ballerup",
  "keywords": {
    "topics": ["sensors", "hydroponics"],
    "tools": ["arduino"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Developer"
  }, {
    "name": "Jesper Kirial",
    "role": "Developer"
  }, {
    "name": "Taras Karpin",
    "role": "Developer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # GROW-E */
    }
    <h2>{`Description`}</h2>
    <p>{`Refurbishing a dishwashing machine to grow plants inside.
And no, not the kind of herbs you are thinking right now.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      